<template>
  <v-container>
  </v-container>
</template>

<script>
  export default {
    data:()=>({
      //
    }),
    created() {
      this.$store.dispatch('resetSchool')
      this.$store.dispatch('logout')
      this.$store.dispatch('resetApparel')
    this.$store.dispatch('resetSkin')
    this.$store.dispatch("resetToken");
    },
    mounted() {
      this.$router.push({name:'PageMainHome'})
    },
  }
</script>
